import { Tooltip } from "@mui/material";
import React from "react";

const Emoji = props => (
  <Tooltip title={props.tooltip ?? ""}>
    <span
      className="emoji"
      role="img"
      aria-label={props.label ? props.label : ""}
      aria-hidden={props.label ? "false" : "true"}
      style={{ fontSize: "smaller" }}
    >
      {props.symbol}
    </span>
  </Tooltip>
);

export default Emoji;
