import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import InstagramIcon from "@mui/icons-material/Instagram";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Emoji from "../common/emoji";

export const main = [
  {
    title: `YouTube`,
    url: `https://www.youtube.com/c/somewhatjp`,
    icon: <YouTubeIcon />,
    description: "My main platform for sharing longer-form content.",
    emojis: (
      <>
        <Emoji symbol="🍁" tooltip="English" />
        <Emoji symbol="🍙" tooltip="Japanese" />
      </>
    ),
  },
  {
    title: `Twitter`,
    url: `https://twitter.com/somewhatjp`,
    icon: <TwitterIcon />,
    description: "My main platform for everything else. Easiest to catch me on here.",
    emojis: (
      <>
        <Emoji symbol="🍁" tooltip="English" />
        <Emoji symbol="🍙" tooltip="Japanese" />
      </>
    ),
  },
  {
    title: `Ikinari Japanese (Podcast)`,
    url: `https://anchor.fm/ikinarijapanese `,
    icon: <PodcastsIcon />,
    description:
      "Weekly, no-frills, casual conversation between a Japanese native and I. Probably the best way to keep up with what I'm up to recently.",
    emojis: <Emoji symbol="🍙" tooltip="Japanese" />,
  },
  // {
  //   title: `Everyone's Mikata (Podcast)`,
  //   url: `https://anchor.fm/mikata`,
  //   icon: <PodcastsIcon />,
  //   description: "A place for foreigners to talk about their passions while sharing their unique perspectives.",
  //   emojis: <Emoji symbol="🍙" tooltip="Japanese" />,
  // },
  {
    title: `Instagram`,
    url: `https://instagram.com/somewhat.jp`,
    icon: <InstagramIcon />,
    emojis: (
      <>
        <Emoji symbol="🍁" tooltip="English" />
        <Emoji symbol="🍙" tooltip="Japanese" />
      </>
    ),
  },
  {
    title: `Ko-fi`,
    url: `https://ko-fi.com/somewhatjp`,
    icon: <FreeBreakfastIcon />,
    description: "Support my coffee addiction?",
    emojis: (
      <>
        <Emoji symbol="🍁" tooltip="English" />
        <Emoji symbol="🍙" tooltip="Japanese" />
      </>
    ),
  },
];

export const secret = [];
